import Img from "gatsby-image"
import styled from "styled-components"
import React from "react"

const StyledImage = styled(Img)`
  box-sizing: border-box;
  border: solid 8px #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 4rem;
  cursor: pointer;
`

const AlbumImage = ({ onClick, ...props }) => (
  <div onClick={onClick}>
    <StyledImage {...props} />
  </div>
)

export default AlbumImage
