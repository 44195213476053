import React, { useState } from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import AlbumImage from "../components/AlbumImage"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const PhotoGrid = styled.div`
  column-count: 3;
  > * {
    transform: translateZ(0);
    break-inside: avoid;
  }

  @media (max-width: 1000px) {
    column-count: 2;
  }
  > :first-child {
    margin-top: 0;
  }
`

const ModalBackground = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: #2f2f2f59;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 900px;
  max-width: 976px;
`

const Dismiss = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 5px;
  background: #ffffffa3;
  width: 40px;
  height: 40px;
  font-size: 32px;
  line-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #ffffffdb;
  }
`

const ImageModal = ({ image, dismiss }) => {
  if (!image) {
    return null
  }
  return (
    <ModalBackground onClick={dismiss}>
      <ModalContainer>
        <AlbumImage fluid={image} onClick={dismiss} />
        <Dismiss>×</Dismiss>
      </ModalContainer>
    </ModalBackground>
  )
}

const PhotosPage = () => {
  const [image, setImage] = useState(null)

  return (
    <Layout title="Photos">
      <ImageModal image={image} dismiss={() => setImage(null)} />
      <Container pad>
        <PhotoGrid>
          <StaticQuery
            query={graphql`
              query {
                bulkImages: allFile(
                  filter: { relativePath: { glob: "bulk/*" } }
                  sort: { fields: [modifiedTime], order: DESC }
                ) {
                  edges {
                    node {
                      childImageSharp {
                        fluid(maxWidth: 960, quality: 75) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data =>
              data.bulkImages.edges.map(({ node }) => (
                <AlbumImage
                  onClick={() => setImage(node.childImageSharp.fluid)}
                  fluid={node.childImageSharp.fluid}
                />
              ))
            }
          />
        </PhotoGrid>
      </Container>
    </Layout>
  )
}

export default PhotosPage
